
$borderRadius = 15px
$springColor = #c0ebd7
$summerColor = #f47983
$autumnColor = #fff2df
$winterColor = #f0fcff

cardshadow()
  box-shadow 30px 20px 3px 0 rgba(0,0,0,.12)

@font-face
  font-family: against
  src: url(./fonts/againts.ttf), url(./fonts/againts.otf);

font-default()
    font-family helvetica, "游ゴシック体", 'Hiragino Sans GB', 'Microsoft Yahei Light', 'Microsoft YaHei', 'WenQuanYi Micro Hei', lucida, sans-serif

simsun()
    font-family Arial, Helvetica, tahoma, verdana, "宋体", SimSun, "华文细黑", STXihei, sans-serif

fangsong()
    font-family Georgia, "Times New Roman", "FangSong", "仿宋", STFangSong, "华文仿宋", serif

*
  box-sizing  border-box 
html
  background-color: #e7e7e7
  scroll-behavior: smooth;
  font-size 16px;
.hide{
  display: none;
}


.song
  fangsong();
.e444
  color: #444
a
  text-decoration: none;
  color: #444;
  &:hover
    text-decoration: underline
html
body
  height: 100%;
  width: 100%
  margin: 0
  padding: 0
  font-default()

clearfix()
  &:after
    content ''
    display: table
    clear: both

.seasons
  height: 100%;
  &>div
    overflow: hidden
    position: relative
    height: 100%;
    perspective: 3000px;
    
.seasons .s-title
  position: absolute;
  right: 0;
  bottom: 40px;
  font-size: 5rem;
 
  
.bg
    position absolute 
    left 0
    top 0
    z-index 5
    text-align center
    h1
      margin 0 
      font-family font-default()
      white-space nowrap
      letter-spacing 2vh
      font-size 20vh

.summer .bg
  color $winterColor
  text-align left
  z-index -1 

.white
  display: inline-block
  z-index 10
  font-size 1rem
  padding: 1.875rem
  margin 1.875rem
  background-color: rgba(255,255,255,.93);
  cardshadow()
.lifelong
  position: absolute
  left: 30px
  top 30px 
  color: #fafafa;
  background-color: #0e25ce;
  padding: 20px 30px;
  padding 20px
  z-index 40
  simsun()
  h1
    font-size 24px
.spring
  display flex 
  justify-content center
  align-items center
  background-color: $springColor
  .white
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%)
    max-width: 37.5rem;



   
  
dt
  font-weight: bold;
dd 
  margin-left 2.5rem
  transform: translateY(-100%)

.summer
  display flex  
  justify-content center
  align-items center
  background-color: $summerColor;
  .white
    background-color $autumnColor
    display: inline-block
    text-align: left
    
    

.autumn
  background-color: #fff2df
  padding-top: 2.5rem
  &>h1
    text-align: center
    position: relative
    font-weight: normal
    padding: 1.25rem 1.875rem
    
.winter
  display flex  
  justify-content center
  align-items center
  simsun()
  background-color: $winterColor
  .white
    background-color none
    text-align: left
    color #444
    line-height: 2
    box-shadow none
  .right
    text-align: right;
    font-default()
.collections
  display:  flex
  justify-content: center;
  align-items: stretch
  flex-direction: row
  flex-wrap: wrap;
  padding 1.25rem 1.875rem

.card
  text-align: left
  width 20rem
  flex-basis: 20rem
  flex-shrink: 0;
  margin 1.25rem 1.875rem
  padding 1.25rem 1.875rem
  border-radius:  0.25rem
  background-color: #fff;
  // box-shadow: 0 3px 20px rgba(0,0,0,.24)

  h3
    font-weight: normal
  footer
    text-align: right
footer.cite
  position: absolute;
  bottom: 0;
  right: 40px;

@media screen and (max-width:550px)

  .winter .white 
    overflow: auto;
    width: 100%;
  footer.cite
    right: 2em;

@media screen and (min-width:1440px) 
  html
    font-size 32px
  
